<template>
	<div>
        <template>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0 mb-3">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable hide-details label="Year" :items="years" :menu-props="{top: false, offsetY: true}" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel :key="index" v-for="(item, index) in items">
                            <div :class="{ 'no-data': item.shift.length <= 0 }">
                                <v-expansion-panel-header>
                                    <span v-text="item.start_date+' -- '+item.end_date"></span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-if="item.shift.length > 0">
                                        <v-btn class="non-cpf-button" small color="primary" @click="openDownloadDialog(item)">Download Non CPF Payroll</v-btn>
                                    </div>
                                    <div v-else class="no-data-text">
                                        <span>No data available</span>
                                    </div>
                                </v-expansion-panel-content>
                            </div>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
        <download-dialog ref="downloadDialog" @downloaded="handleDownloaded"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { makeYearList } from '@/utils/dates'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import NonCpfPayrollDownloadDialog from './NonCpfPayrollDownloadDialog.vue';
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue';

export default {
	name: 'NonCPFPayroll',
	components: {
        messageNotifier: MessageNotifier,
        downloadDialog: NonCpfPayrollDownloadDialog,
        simpleActionDialog: SimpleActionDialog,
	},
	data () {
		return {
            panel: [],
			loading: false,
			selectedIndex: -1,
			filters: {
				year: '',
                page: 1
			},
			permissions: {},
			emptyProjects: [],
			items: [],
			headers: [],
			pagination: {},
            can_download: false,
		}
	},
	computed: {
        notifier() {
            return this.$refs.notifier
        },
        years() {
            return makeYearList(2021, 2)
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
            // && checkCanDownload(item) === true
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/shift/getNonCPFPayrollShiftByDate', this.filters).then((response) => {
                this.items = response.data.items
                this.pagination = response.data.pagination
                this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { year: '', project: '', status: '', page: 1}
		},
        updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getViewUrl: function(id){
            return '/epanel/payroll/project/' + id + '/info'
        },
        hasMenu: function(item) {
            return item.can_view
        },
        checkCanDownload: function(items) {
            let payrolls = items.payrolls
            let status_checker = []

            payrolls.map(project => {
                if(project.status === "Draft") {
                    status_checker.push(false)
                } else if(project.status === "Published"){
                    status_checker.push(true)
                }
            })
            
            return this.trueChecker(status_checker)
        },
        trueChecker: function(arr) {
            return arr.every(element => element === true);
        },
        openDownloadDialog: function(item) {
            let start_date = item.start_date
            let end_date = item.end_date
            const object = { start_date: start_date, end_date: end_date }
            this.downloadDialog.updateObject(object)
            this.downloadDialog.open()
        },
        handleDownloaded: function(){
            this.loadingDialog.open()
        },
        checkQuantity: function(payrolls) {
            let empty_array = []
            for (let i = 0; i < payrolls.length; i ++) {
                empty_array.push(payrolls[i].quantity)
            }

            let sum = empty_array.reduce((a, b) => a + b, 0)
            return sum
        },
	}
}

</script>

<style>
    .non-cpf-button {
        margin-bottom: 10px
    }

    .no-data-text {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: #00000061;
        text-align: center;
    }

    .no-data {
        color: #00000061;
    }
</style>