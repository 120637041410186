<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-form ref="form" action="/report/payroll/project/download_all_non_cpf/">
            <v-card>
                <v-card-title>Download Payroll Report</v-card-title>
                <v-card-text class="pb-0 my-1">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <input type="hidden" name="start_date" :value="object.start_date" />
                            <input type="hidden" name="end_date" :value="object.end_date" />
                            <v-alert dense class="text-subtitle-1 mb-0" type="warning">Are you sure that you want to download the Non CPF payroll report?</v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Later</v-btn>
                    <v-btn text color="primary" @click="download">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>


<script>

export default {
    name: 'NonCpfPayrollDownloadDialog',
    data() {
        return {
            dialog: false,
            object: {
                start_date: '',
                end_date: ''
            },
            errors: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        updateObject: function(item) {
            this.object = item
        },
        download: function() {
            this.form.$el.submit()
            this.close()
            this.$emit('downloaded')
        }
    }
}

</script>